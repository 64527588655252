@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.pdfViewer,
iframe {
  margin: 0 auto;
  width: 100% !important;
  height: 100vh !important;
}

.menu-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-transform: uppercase;
  list-style: none;
  gap: 3rem;
  font-size: small;
  cursor: pointer;
}

.mobile-menu-link {
  display: hidden;
}

@media (max-width: 600px) and (min-width: 300px) {
  .mobile-menu-link {
    position: fixed;
    list-style: none;
    gap: 2rem;
    font-weight: 600;
    flex-direction: column;
    right: 0.5rem;
    top: 3.5rem;
    min-width: 50%;
    padding: 2rem;
    display: flex;
    border-radius: 2rem;
    align-items: flex-start;
    transition: all 300ms ease;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }
  .menu-link {
    display: none;
  }
}
